
import { Component, Vue } from "vue-property-decorator";
import { PricingComparisonDataRow } from "@jfrog-ba/myjfrog-common";
import { registrationsService } from "@/services/registrations";
import { JFGrid } from "jfrog-ui-vue-essentials";
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";

@Component({ name: "ComparisonTable", components: { LoadingMask, JFGrid } })
export default class ComparisonTable extends Vue {
  allData!: PricingComparisonDataRow[];
  isLoading = false;
  isOpen = false;

  mounted() {
    this.fetchDataPromise();
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  get btnClass() {
    return this.isOpen ? "active" : "";
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  gridProps: any = {
    theme: "light",
    displayToolbar: false,

    columns: [
      {
        label: "",
        field: "index",
        sortable: false,
        flexGrow: 0,
        cellCssClasses: "hidden",
      },
      {
        label: "Title",
        field: "title",
        sortable: false,
        flexGrow: 2,
        tooltipConverter: (val: string, row: PricingComparisonDataRow) => (row.tooltip ? row.tooltip : ""),
        getHtml: (val: string) => {
          return val;
        },
      },
      {
        label: "Free",
        field: "free",
        sortable: false,
        flexGrow: 1,
        cellCssClasses: "data-cell",
        getHtml: (val: string) => this.getCellHtml(val),
      },
      {
        label: "Pro",
        field: "pro",
        sortable: false,
        flexGrow: 1,
        cellCssClasses: "data-cell",
        getHtml: (val: string) => this.getCellHtml(val),
      },
      {
        label: "Enterprise X",
        field: "enterpriseX",
        sortable: false,
        flexGrow: 1,
        cellCssClasses: "data-cell",
        getHtml: (val: string) => this.getCellHtml(val),
      },
      {
        label: "Enterprise+",
        field: "enterprisePlus",
        sortable: false,
        flexGrow: 1,
        cellCssClasses: "data-cell",
        getHtml: (val: string) => this.getCellHtml(val),
      },
    ],
    state: {
      page: 1,
      orderBy: "index",
      order: "asc",
    },
    config: {
      fetchDataPromise: this.fetchDataPromise,
      noPagination: true,
      mode: "client",
      onFilter: () => true,
    },
  };

  fetchDataPromise(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.isLoading = true;
        this.allData = await registrationsService.priceComparisonData();
        this.isLoading = false;
        resolve({ data: this.allData, total: this.allData.length });
      } catch (error) {
        this.isLoading = false;
        this.$log.error(error);
        this.$jfNotification.error({
          text: "couldn't load comparison data",
        });
        reject(error);
      }
    });
  }

  getCellHtml(val: string) {
    switch (val) {
      case "":
        return `<img class="check" src="${this.iconSrc}" alt="" />`;
      case "0":
        return "-";
      default:
        return val;
    }
  }

  get iconSrc() {
    try {
      return this.$jfImages.get("check-b.svg");
    } catch (e) {
      this.$log.error("Failed to retrieve icon : check.svg");
    }
  }
}
