
import Chat from "@/components/common/Chat.vue";
import PricingTable from "@/components/landingPages/quickPurchase/pricingTables/PricingTable.vue";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";
import { PlanModel, QuickPricingTableProps, QuickPurchaseData } from "@/types/localtypes";
import { SubscriptionTypes } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import ComparisonTable from "@/components/pricingTable/ComparisonTable.vue";

@Component({
  name: "QuickPurchasePricing",
  components: { QuickPurchasePageTitle, PricingTable, Chat, ComparisonTable },
})
export default class QuickPurchasePricing extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;
  @Inject() readonly globalBus!: Vue;
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  @Action("setHasUpgraded", { namespace: "quickPurchase" })
  setHasUpgraded!: (hasUpgraded: boolean) => Promise<void>;

  get currentPaymentType() {
    return this.quickPurchaseData.currentPaymentType;
  }

  get isCurrentlyFree() {
    return this.currentPaymentType && this.currentPaymentType === "FREE";
  }

  get allPlans(): QuickPricingTableProps["allPlans"] {
    if (!this.proTeamSubscriptionType || !this.entSubscriptionType || !this.entXSubscriptionType) {
      this.$log.error("PaymentType not found");
      this.notifyError(this.$jfMessages.subscriptions_upgrade_error_unknown);
      return [];
    }

    if (!this.currentPaymentType) {
      this.$log.error("Current PaymentType not found");
      this.notifyError(this.$jfMessages.subscriptions_upgrade_error_unknown);
      return [];
    }

    const plans = [];
    if (this.isFree) {
      plans.push(
        this.$jfPricing.createPlan({
          subscriptionType: "FREE",
          currentPlan: true,
          isDisabled: true,
          canPurchasePipelines: true,
          buttonText: "Current Plan",
          isProcessable: true,
          actionBtnDisabled: true,
          core: "DEVOPS",
        }),
      );
    }
    plans.push(
      this.$jfPricing.createPlan(
        {
          currentPlan: false,
          canPurchasePipelines: true,
          buttonText: this.isTrialEnterpriseSubscription ? "Buy Now" : "Upgrade Now",
          isProcessable: true,
          actionBtnDisabled: false,
          core: "DEVOPS",
        },
        this.proTeamSubscriptionType,
        [],
        this.quickPurchaseData.promotionItems,
      ),
      this.$jfPricing.createPlan(
        {
          subscriptionType: "ENTERPRISE_MONTHLY",
          currentPlan: false,
          canPurchasePipelines: true,
          buttonText: this.isTrialEnterpriseSubscription ? "Buy Now" : "Upgrade Now",
          actionBtnDisabled: false,
          isProcessable: true,
          displayTrialRibbon: this.isTrialEnterpriseSubscription,
          core: "DEVOPS",
          forceAdditionalType: true,
        },
        this.entSubscriptionType,
        [this.entXSubscriptionType],
      ),
      this.$jfPricing.createPlan({
        subscriptionType: "CLOUD_ENTERPRISE_PLUS",
        buttonText: "Get a Quote",
        canPurchasePipelines: true,
        isProcessable: true,
        isUpgrade: true,
        core: "DEVOPS",
      }),

      this.$jfPricing.createPlan({
        subscriptionType: "ENT",
        currentPlan: false,
        currentTrialPlan: this.isTrialEnterpriseSubscription,
        canPurchasePipelines: true,
        buttonText: "Get a Quote",
        actionBtnDisabled: false,
        isProcessable: true,
        core: "DEVSECOPS",
      }),
      this.$jfPricing.createPlan({
        subscriptionType: "CLOUD_ENTERPRISE_PLUS",
        buttonText: "Get a Quote",
        canPurchasePipelines: true,
        isProcessable: true,
        isUpgrade: true,
        core: "DEVSECOPS",
      }),
    );

    return plans;
  }

  get pricingTableProps(): QuickPricingTableProps {
    return { allPlans: this.$jfPricing.fillTooltipNumbers(this.allPlans) };
  }

  get freeSubscriptionType(): SubscriptionTypes | undefined {
    return (
      this.quickPurchaseData.subscriptionTypes &&
      this.quickPurchaseData.subscriptionTypes.find(value => value.paymentType === "FREE")
    );
  }

  get proTeamSubscriptionType(): SubscriptionTypes | undefined {
    return (
      this.quickPurchaseData.subscriptionTypes &&
      this.quickPurchaseData.subscriptionTypes.find(value => value.paymentType === "PRO_TEAM_MONTHLY")
    );
  }

  get entSubscriptionType(): SubscriptionTypes | undefined {
    return (
      this.quickPurchaseData.subscriptionTypes &&
      this.quickPurchaseData.subscriptionTypes.find(value => value.paymentType === "ENTERPRISE_MONTHLY")
    );
  }

  get entXSubscriptionType(): SubscriptionTypes | undefined {
    return (
      this.quickPurchaseData.subscriptionTypes &&
      this.quickPurchaseData.subscriptionTypes.find(value => value.paymentType === "ENTERPRISE_X_MONTHLY")
    );
  }

  get isTrialEnterpriseSubscription(): boolean {
    return this.currentPaymentType === "TRIAL_ENTERPRISE";
  }

  get isFree(): boolean {
    return this.currentPaymentType === "FREE";
  }

  toCheckout(planName: string, planModel: PlanModel) {
    // @ts-ignore
    const additionalType =
      planModel.additionalTypes && planModel.additionalTypes.length && planModel.additionalTypes[0];
    const purchase = planModel.purchase;

    this.$router.push({
      name: "quick-purchase-checkout",
      params: {
        planName,
        paymentType: planModel.paymentType,
        planId: planModel.planId,
        additionalPlanId: (additionalType && additionalType.ratePlanId) || "",
        additionalPaymentType: (additionalType && additionalType.paymentType) || "",
        purchaseType: purchase ? purchase.type : "",
        purchaseCurrency: purchase ? "" + purchase.currency : "",
        purchaseAmount: purchase ? "" + purchase.amount : "",
        isInPromotionStr: "" + !!planModel.promotionItem,
      },
    });
  }

  toGetAQuote(planName: string, planModel: PlanModel) {
    this.$router.push({
      name: "quick-purchase-get-a-quote",
      params: { planName, paymentType: planModel.paymentType, core: planModel.core },
    });
  }

  async toThankYou() {
    await this.setHasUpgraded(true);
    this.$router.push({ name: "quick-purchase-thank-you", params: { planName: "The Plan Name" } });
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({
      goal_key: "upgrade",
      isSuccess: false,
      errorMessage: errorMessage,
      customData: [{ key: "accountNumber", value: this.quickPurchaseData.serverName }],
    });
  }

  mounted() {
    this.quickPurchaseBus.$emit("shouldDisplayHostedPlanCta", true);
  }

  isDevSecOpsCore(planModel: PlanModel) {
    return planModel.core === "DEVSECOPS";
  }

  onPricingItemActionClick(planModel: PlanModel) {
    switch (planModel.paymentType) {
      case "PRO_TEAM_MONTHLY":
        return this.toCheckout("Pro", planModel);
      case "ENTERPRISE_MONTHLY":
        if (this.isDevSecOpsCore(planModel)) {
          return this.toGetAQuote("Enterprise X", planModel);
        }
        return this.toCheckout("Enterprise X", planModel);
      case "ENTERPRISE_PLUS":
        return this.toGetAQuote("Enterprise+", planModel);
      default:
        this.$log.error(`no actions for paymentType '${planModel.paymentType}'`);
    }
  }

  busEvents: { [key: string]: (...params: any[]) => any } = {
    pricingItemActionClick: this.onPricingItemActionClick,
  };

  created() {
    for (let busEventsKey in this.busEvents) {
      this.quickPurchaseBus.$on(busEventsKey, this.busEvents[busEventsKey]);
    }
  }

  beforeDestroy() {
    for (let busEventsKey in this.busEvents) {
      this.quickPurchaseBus.$off(busEventsKey, this.busEvents[busEventsKey]);
    }
  }
}
