
declare var window: any;
declare var DRIFT_EMBED_ID: string;
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Chat extends Vue {
  created() {
    this.initDrift();
  }
  initDrift() {
    if (window.drift) return;
    let t = (window.driftt = window.drift = window.driftt || []);
    if (!t.init) {
      if (t.invoked)
        return void (window.console && window.console.error && window.console.error("Drift snippet included twice."));
      (t.invoked = !0),
        (t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"]),
        (t.factory = function(e: any) {
          return function() {
            let n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }),
        t.methods.forEach(function(e: any) {
          t[e] = t.factory(e);
        }),
        (t.load = function(t: any) {
          let e = 3e5,
            dd: any = new Date(),
            n = Math.ceil(dd / e) * e,
            o = document.createElement("script");
          (o.type = "text/javascript"),
            (o.async = !0),
            (o.crossOrigin = "anonymous"),
            (o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js");
          let i: any = document.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(o, i);
        });
    }
    window.drift.SNIPPET_VERSION = "0.3.1";
    window.drift.load(DRIFT_EMBED_ID);
  }
}
